import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { Observable } from "rxjs";

import { environment } from "@env/environment";

import { UtilService } from "@app/shared/services/util.service";

import { TGTBridge } from "./models/TGTBridge";
import { StudentUserExtended } from "./models/StudentUserExtended";
import { ITask } from "./models/ITask";
import { SchoolGroup } from "./models/SchoolGroup";

import { TeachingMethod } from "@app/shared/models/entities/TeachingMethod";
import { SchoolPublicData } from "@app/school/models/SchoolPublicData";
import { PracticeUserAnswer } from "@app/user/models/PracticeUserAnswer";
import { IExamAttempt } from "@app/exambundle/models/IExamAttempt";
import { DomainCourseBridgeProgress } from "./models/DomainCourseBridgeProgress";
import { Group, GroupV3 } from "./models/Group";
import { GroupInviteCode } from "./models/GroupInviteCode";
import { TeacherGroupBridge } from "./models/TeacherGroupBridge";
import { TrainingTypeEnum } from "@app/shared/enums/training-type-enum";
import { DatePipe } from "@angular/common";
import { Activity } from "@app/models/Activity";
import { GroupCourseStats } from "./models/GroupCourseStats";
import { GroupTrainingProgress } from "./models/GroupTrainingProgress";
import { StudentCourseData } from "./models/StudentCourseData";
import { GroupExamAttempts } from "./models/GroupExamAttempts";
import { ExamUserAnswer } from "@app/user/models/ExamUserAnswer";
import { Student } from "./models/Student";
import { SchoolPrivateData } from "@app/school/models/SchoolPrivateData";
import { ModelService } from "@app/shared/services/model.service";

export interface ActivitiesParams {
  training_ids: number[];
  group_ids?: number[];
  course_ids?: number[];
  include_exam_activities?: boolean;
  include_regular_activities?: boolean;
}

export interface AddNewTGTBridge extends Partial<Omit<TGTBridge, "training" | "teaching_method">> {
  training: number;
  teaching_method: number;
}

@Injectable({
  providedIn: "root",
})
export class SchoolService {
  schoolsV2Url: string = environment.apiEndpointV2 + "/schools/";
  schoolsPublicDataUrl: string = environment.apiEndpointV2 + "/schools/";

  constructor(
    private http: HttpClient,
    private modelService: ModelService,
    private utilService: UtilService,
    private datePipe: DatePipe
  ) {}

  getSchools(parameters: string): Observable<SchoolPublicData[]> {
    return this.http.get<SchoolPublicData[]>(this.schoolsPublicDataUrl + "?" + parameters);
  }

  getGroups(): Observable<GroupV3[]> {
    return this.modelService.getModels(environment.apiEndpointV3 + "/students/groups", GroupV3);
  }

  getGroup(groupId: number): Observable<Group> {
    return this.http.get<Group>(environment.apiEndpointV2 + "/groups/" + groupId);
  }

  updateGroup(group: Group): Observable<Group> {
    return this.http.patch<Group>(environment.apiEndpointV2 + "/groups/" + group.id, group);
  }

  getGroupInviteCodes(groupId: number, params?: { is_generic_code: boolean }): Observable<GroupInviteCode[]> {
    return this.http.get<GroupInviteCode[]>(
      environment.apiEndpointV2 + "/groups/" + groupId + "/group-invite-codes?" + this.utilService.dictionaryToQueryParams(params)
    );
  }

  activateInviteCode(user: PreSignUpUser, inviteCode: string): Observable<any> {
    return this.http.patch<any>(
      environment.apiEndpointV2 + "/group-invite-codes",
      {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        password: user.password,
        control_answer_1: user.controlAnswer,
        control_answer_2: user.controlAnswer2,
        invite_code: inviteCode,
      },
      {
        observe: "response",
      }
    );
  }

  enrollAhSchool(user: PreSignUpUser): Observable<any> {
    return this.http.post<any>(
      this.schoolsV2Url + "enroll-ah",
      {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        password: user.password,
      },
      {
        observe: "response",
      }
    );
  }

  enrollDominosSchool(user: PreSignUpUser): Observable<any> {
    return this.http.post<any>(
      this.schoolsV2Url + "enroll-dominos",
      {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        password: user.password,
        control_answer_1: user.controlAnswer,
      },
      {
        observe: "response",
      }
    );
  }

  createGroup(group: Group): Observable<Group> {
    return this.http.post<Group>(environment.apiEndpointV2 + "/schools/" + group.school + "/groups", group);
  }

  createGroupTeacherBridge(group: Group): Observable<TeacherGroupBridge> {
    return this.http.post<TeacherGroupBridge>(environment.apiEndpointV2 + "/groups/" + group.id + "/teacher-bridges", {});
  }

  getStudents(schoolId: number, groupId: number): Observable<StudentUserExtended[]> {
    return this.http.get<StudentUserExtended[]>(this.schoolsV2Url + schoolId + "/groups/" + groupId + "/students");
  }

  getTeachingMethods(trainingId: number): Observable<TeachingMethod[]> {
    return this.http.get<TeachingMethod[]>(environment.apiEndpoint + "/trainings/" + trainingId + "/teaching-methods");
  }

  getGroupTeachingMethodProgress(groupId: number, teachingMethodId: number): Observable<DomainCourseBridgeProgress[]> {
    return this.http.get<DomainCourseBridgeProgress[]>(
      environment.apiEndpointV3 + "/groups/" + groupId + "/teaching-methods/" + teachingMethodId + "/progress?"
    );
  }

  getSchool(schoolId: number): Observable<SchoolPublicData> {
    return this.http.get<SchoolPublicData>(this.schoolsPublicDataUrl + schoolId + "/");
  }

  createUserGroupBridgeUsingInviteCode(inviteCode: string): Observable<HttpResponse<Student>> {
    return this.http.post<Student>(environment.apiEndpoint + "/user-group-bridges?invite_code=" + inviteCode, {}, { observe: "response" });
  }

  addStudentToAClass(
    schoolId: number,
    groupId: number,
    body: {
      first_name?: string;
      last_name?: string;
      email?: string;
    }
  ): Observable<Student> {
    return this.http.post<Student>(environment.apiEndpointV2 + "/schools/" + schoolId + "/groups/" + groupId + "/students", body);
  }

  getStudentTasksV3(): Observable<ITask[]> {
    return this.http.get<ITask[]>(environment.apiEndpointV3 + "/students/tasks");
  }

  updateExamUserAnswer(examUserAnswerId: number, body: any): Observable<ExamUserAnswer> {
    return this.http.patch<ExamUserAnswer>(environment.apiEndpointV3 + "/students/exam-user-answers/" + examUserAnswerId, body);
  }

  updateUserAnswer(userAnswerId: number, body: any): Observable<PracticeUserAnswer> {
    return this.http.patch<PracticeUserAnswer>(environment.apiEndpointV3 + "/students/user-answers/" + userAnswerId, body);
  }

  getTeacherSchools(): Observable<SchoolGroup[]> {
    return this.http.get<SchoolGroup[]>(environment.apiEndpointV2 + "/teachers/schools");
  }

  getStudentSchools(): Observable<SchoolPrivateData[]> {
    return this.http.get<SchoolPrivateData[]>(environment.apiEndpointV2 + "/students/schools");
  }

  addStudent(
    schoolId: number,
    body: {
      registrator_email: string;
      first_name: string;
      last_name: string;
      email: string;
      group: string;
    }
  ): Observable<SchoolPublicData[]> {
    return this.http.post<SchoolPublicData[]>(environment.apiEndpointV2 + "/schools/" + schoolId + "/students", body);
  }

  authorizedRegistersCheck(email: string): Observable<SchoolPublicData[]> {
    return this.http.post<SchoolPublicData[]>(environment.apiEndpointV2 + "/authorized-registrators-check", { email: email });
  }

  authorizedRegistersVerfication(email: string, students: any[]): Observable<any> {
    return this.http.post<any>(environment.apiEndpointV2 + "/authorized-registrators-verification", { email: email, students: students });
  }

  getGroupsActivities(startDate: Date, endDate: Date, params?: ActivitiesParams): Observable<Activity[]> {
    const trainingType = UtilService.isSchool24() ? TrainingTypeEnum.School24 : TrainingTypeEnum.OnlineSlagen;
    const url =
      environment.apiEndpointV3 +
      "/groups/activities?training_type=" +
      trainingType +
      "&start_date=" +
      this.datePipe.transform(startDate, "yyyy-MM-ddTHH:mm") +
      "&end_date=" +
      this.datePipe.transform(endDate, "yyyy-MM-ddTHH:mm") +
      "&" +
      (params && this.utilService.dictionaryToQueryParams(params as Record<string, any>));
    return this.http.get<Activity[]>(url);
  }

  getGroupsTrainingProgress(
    trainingId: number,
    params?: { group_ids?: number[]; course_ids?: number[] }
  ): Observable<GroupTrainingProgress[]> {
    const trainingType = UtilService.isSchool24() ? TrainingTypeEnum.School24 : TrainingTypeEnum.OnlineSlagen;
    return this.http.get<GroupTrainingProgress[]>(
      environment.apiEndpointV3 +
        "/groups/trainings/" +
        trainingId +
        "/progress?training_type=" +
        trainingType +
        "&" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getGroupTrainingCourseStats(groupId: number, trainingId: number, params?: { user_ids?: number[] }): Observable<GroupCourseStats[]> {
    return this.http.get<GroupCourseStats[]>(
      environment.apiEndpointV3 +
        "/groups/" +
        groupId +
        "/trainings/" +
        trainingId +
        "/courses/progress?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getStudentCourseProgress(groupId: number, courseId: number): Observable<StudentCourseData[]> {
    return this.http.get<StudentCourseData[]>(environment.apiEndpointV3 + "/groups/" + groupId + "/courses/" + courseId + "/data");
  }

  getGroupsExamAttempts(params?: {
    group_ids?: number[];
    training_ids?: number[];
    exam_ids?: number[];
    fields?: string;
  }): Observable<GroupExamAttempts[]> {
    return this.http.get<GroupExamAttempts[]>(
      environment.apiEndpointV3 + "/groups/exam-attempts?" + this.utilService.dictionaryToQueryParams(params)
    );
  }

  getExamAttempt(examAttemptId: number) {
    return this.http.get<IExamAttempt>(environment.apiEndpointV3 + "/teachers/exam-attempts/" + examAttemptId);
  }
}
